
import { defineComponent, inject } from 'vue';
import {
  Form,
  Field
} from 'vee-validate';
import {
  DmWrapper,
  DmInput,
  DmButton
} from '@dobrymechanik/vue-ui';
import { CookiesKey, LocalStorageKey } from '@/models/storage';
import { URI_LOGIN } from '@/api/endpoints';
import { mapActions } from 'vuex';
import { CREATE_NOTIFICATION } from '@/store/notifications/actions';
import { TrackEvent, TrackedEventType, TrackedEventCategory } from '@/models/track-event';
import jwtDecode from 'jwt-decode';
import { RouteName } from '@/router/models';

interface Credentials {
  email: string;
  password: string;
}

export default defineComponent({
  name: 'Login',
  components: {
    DmForm: Form,
    DmField: Field,
    DmWrapper,
    DmInput,
    DmButton
  },
  beforeRouteEnter (_to, _from, next) {
    if (localStorage.getItem(LocalStorageKey.IS_LOGGED) === '1') {
      next({
        name: RouteName.SCHEDULER
      });
    } else {
      next();
    }
  },
  setup () {
    return {
      trackEvent: inject('trackEvent') as TrackEvent
    };
  },
  data () {
    return {
      loading: false as boolean,
      homeRoute: {
        name: RouteName.HOME
      }
    };
  },
  created () {
    this.clearCredentials();
  },
  methods: {
    ...mapActions('notifications', {
      createNotification: CREATE_NOTIFICATION
    }),
    clearCredentials (): void {
      let domain = window.location.hostname;
      const sDomain = domain.split('.');

      if (sDomain.length === 3) {
        sDomain.shift();
        domain = sDomain.join('.');
      }
      this.$cookies.remove(CookiesKey.ACCESS_TOKEN, undefined, domain);
      localStorage.setItem(LocalStorageKey.IS_LOGGED, '0');
    },
    validateEmail (value: string): string | true {
      if (!value) {
        return this.$t('login.noEmail');
      }
      if (!/[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/i.test(value)) {
        return this.$t('login.wrongEmail');
      }
      return true;
    },
    validatePassword (value: string): string | true {
      if (!value) {
        return this.$t('login.noPassword');
      }
      return true;
    },
    submit (values: Credentials): void {
      this.authenticate(values);
    },

    getDomain (): string {
      const domain = window.location.hostname;
      const sDomain = domain.split('.');

      if (sDomain.length === 3) {
        sDomain.shift();
        return sDomain.join('.');
      }
      return domain;
    },

    async authenticate (credentials: Credentials): Promise<void> {
      const data = {
        username: credentials.email,
        password: credentials.password
      };
      try {
        this.loading = true;
        const accessToken = (await this.$http.post<{ token: string }>(URI_LOGIN, data)).data.token;
        this.$cookies.set(CookiesKey.ACCESS_TOKEN, accessToken, undefined, undefined, this.getDomain());
        localStorage.setItem(LocalStorageKey.IS_LOGGED, '1');

        const returnPath = localStorage.getItem(LocalStorageKey.RETURN_PATH);
        localStorage.removeItem(LocalStorageKey.RETURN_PATH);

        const decodedToken: any = jwtDecode(accessToken);
        const userEmail = decodedToken.username;
        const garageId = decodedToken.garage;

        this.trackEvent({
          event: TrackedEventType.SUCCESS,
          category: TrackedEventCategory.LOGIN,
          action: 'Login successful',
          label: 'Correct credentials',
          attr: {
            email: userEmail,
            garageId
          }
        });
        this.$router.push(returnPath || {
          name: RouteName.SCHEDULER,
          query: this.$route.query
        });
      } catch (e) {
        this.createNotification({
          message: this.$t('login.loginFailed'),
          type: 'error'
        });
        this.trackEvent({
          event: TrackedEventType.ERROR,
          category: TrackedEventCategory.LOGIN,
          action: 'Login failed',
          label: 'Wrong credentials'
        });
      }
      this.loading = false;
    },
    onLoginClick (): void {
      this.trackEvent({
        event: TrackedEventType.CLICK,
        category: TrackedEventCategory.LOGIN,
        action: 'Clicked login button'
      });
    }
  }
});
